// angular
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'

// app
import { ClassDoc } from '../../services/tracing/tracing.interfaces'
import { RegisteredAnimation } from '../../services/tracing/tracing.service'

@Component({
  selector: 'app-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.css'],
})
export class AnimationComponent implements OnInit {
  classDoc: ClassDoc = {
    name: 'AnimationComponentt',
    location: 'src/app/modules/shared/components/animation/animation.component.ts',
    purpose: 'To visualize an animation as a progressing graph',
  }

  @Input() animation: RegisteredAnimation

  @ViewChild('pathElement', { static: true }) pathElement: ElementRef

  path = 'M0, 100'

  ngOnInit() {
    if (!this.animation) {
      return
    }
    // @ts-ignore
    this.animation.stream$.subscribe((progress) => {
      const x = progress.timeProgressDecimal * 100
      const y = (1 - progress.easingProgressDecimal) * 100
      this.path += ` L${x} ${y}`
    })
  }
}
