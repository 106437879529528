import { EnvironmentInterface } from '@environments/environment.interface'

export const environment: EnvironmentInterface = {
  production: true,
  // Visibility UTA: DMS, CR, UMS, Store, EMMA
  serviceBlacklist: ['machine-manager-service', 'ocpp-proxy', 'time-series-data-service', 'impulse-analytics-service', 'admin-service'],
  sentryEnv: 'METROWEST-DEVELOP',
  version: 2250,
  chinaMode: false,
  gateway: false,
  chargeRepay: false,
  name: 'metrowest',
  contactEmail: 'info@metroweststg.wpenginepowered.com',
  copyright: 'Metrowest Group',
  dataPrivacyLink: '',
  siteNoticeLink: ' https://metrowest.com.au/corporate/',
}
