import { Component, Input } from '@angular/core'

export interface IPCTableItem {
  tableItemId: string
  tableItemValue: string
}

@Component({
  selector: 'pc-table',
  templateUrl: './pc-table.component.html',
  styleUrls: ['./pc-table.component.scss'],
})
export class PCTableComponent {
  @Input() titleFirstCol = 'Title First Column'

  @Input() titleSecondCol = 'Title Second Column'

  @Input() items: IPCTableItem[] = []
}
