<div class="pc-table">
  <div class="pc-table__header">
    <div class="pc-table__header__column-a">
      {{ titleFirstCol }}
    </div>
    <div class="pc-table__header__column-b">
      {{ titleSecondCol }}
    </div>
  </div>
  @for (item of items; track item) {
    <div class="pc-table__item">
      <div class="pc-table__item__column-a">
        {{ item.tableItemId }}
      </div>
      <div class="pc-table__item__column-b">
        {{ item.tableItemValue }}
      </div>
    </div>
  }
</div>
